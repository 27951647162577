module.exports = {
  siteTitle: 'Eduard Stere | Developer Relation Engineer | Support Lead Engineer',
  siteDescription:
    'Eduard Stere Alexandru also known as eduardstal is a computer science student that specialises in blockchain management and development.',
  siteKeywords:
    'eduardstal, Eduard Stere, Eduard, Stere, University of Essex, University of Essex Computer Science, Computer Science, Eduard Alexandru Stere, Software Developer, Software, Programmer, Programming',
  siteUrl: 'https://eduardstal.com/',
  siteLanguage: 'en_US',
  cloudflareAnalyticsToken: '0fcc3e2c6eaa4006bce2130364acb124',
  googleAnalyticsID: 'G-L3835RX71F',
  googleVerification: 'null',
  name: 'Eduard Stere',
  location: 'Europe, EEST',
  email: 'eduard.stal@gmail.com',
  github: 'https://github.com/eduardstal',
  twitterHandle: '@eduardstal',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/eduardstal',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/eduardstal/',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/eduardstal',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/eduardstal',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/eduardstal',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Projects',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};

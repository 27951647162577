import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import sr from '@utils/sr';
import { srConfig, email } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  text-align: center;
  max-width: 600px;
  margin: 0 auto 100px;
  a {
    ${mixins.inlineLink};
  }
`;
const StyledHeading = styled(Heading)`
  display: block;
  color: ${colors.green};
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  margin-bottom: 20px;
  justify-content: center;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  &:before {
    bottom: 0;
    font-size: ${fontSizes.sm};
    ${media.desktop`font-size: ${fontSizes.smish};`};
  }
  &:after {
    display: none;
  }
`;
const StyledTitle = styled.h4`
  margin: 0 0 20px;
  font-size: 60px;
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;
const StyledEmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
`;
const DesktopiFrameContainer = {
  backgroundColor: '#ccd6f6',
  position: 'relative',
  overflow: 'hidden',
  paddingTop: '110%', // 92% to hide google footnote
  width: '170%',
  left: '-35%',
  borderRadius: '10px',
};

const MobileiFrameStyles = {
  display:'none',
  visibility:'hidden',
};

const iFrameDiv = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
};

const Contact = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title, buttonText } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  const [isMobile, setIsMobile] = useState(false);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth < 1000); // You can adjust the threshold as needed
  };

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());

    // Add a listener for window resize events when the component mounts
    window.addEventListener('resize', handleWindowResize);

    // Initial check for mobile/desktop on component mount
    handleWindowResize();

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <StyledContainer id="contact" ref={revealContainer}>
      <StyledHeading>What&apos;s Next?</StyledHeading>

      <StyledTitle>{title}</StyledTitle>

      <div dangerouslySetInnerHTML={{ __html: html }} />
        {isMobile && (
          <StyledEmailLink href={`https://calendar.google.com/calendar/appointments/schedules/AcZssZ3SEBb2bQUEI0IYxDLr8TC6uADPP9XG-0tiU9uRlnsAPubhD87s8VAZouHwJbxXnI_s2olr_QAl?gv=true`} target="_blank" rel="nofollow noopener noreferrer">
            {buttonText}
          </StyledEmailLink>
        )}
      <iFrameContainer>
        <iFrameDiv>
          <div style={isMobile ? MobileiFrameStyles : DesktopiFrameContainer}>
            <iframe
              title="Embedded content"
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3SEBb2bQUEI0IYxDLr8TC6uADPP9XG-0tiU9uRlnsAPubhD87s8VAZouHwJbxXnI_s2olr_QAl?gv=true"
              frameBorder="0"
              allowFullScreen
              scrolling='no'
              style={iFrameDiv}
            ></iframe>
          </div>
        </iFrameDiv>
      </iFrameContainer>
    </StyledContainer>
  );
};

Contact.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Contact;
